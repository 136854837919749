import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import MarginComponent from "../components/ui/MarginComponent";
import { TitleComponent } from "../components/ui/TitleComponent";
import FormComponent from "../components/ui/FormComponent";
import { CardPriceComponent } from "../components/ui/CardPriceComponent";

export default function PricePage({ data, location }) {
  return (
    <Layout
      location={location.pathname}
      title="Цена на разработку сайта "
      description="Корпоративный сайт цена. Интернет магазин цена. Лендинг цена"
    >
      <MarginComponent small="sm:mt-8" margin="mt-10">
        <TitleComponent>Цена на разработку сайта</TitleComponent>
        <MarginComponent small="sm:mt-5" margin="mt-4">
          <div className="grid grid-cols-1 gap-5 sm:grid-cols-2">
            {data.price.nodes.map((item) => (
              <CardPriceComponent price={item} show />
            ))}
          </div>
        </MarginComponent>
      </MarginComponent>

      <MarginComponent small="sm:mt-8" margin="mt-10">
        <TitleComponent>Заказать сайт</TitleComponent>
        <FormComponent />
      </MarginComponent>
    </Layout>
  );
}

export const query = graphql`
  query {
    price: allSanityPrice(
      filter: { cat: { elemMatch: { name: { eq: "development" } } } }
      sort: { fields: _createdAt, order: ASC }
    ) {
      nodes {
        id
        name
        cost
        days
        slug {
          current
        }
      }
    }
  }
`;
